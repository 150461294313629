export const getEnvironment = () => {
  const origin = window.location.origin;

  const environment = origin.includes("localhost")
    ? "local"
    : origin.includes("vdi-wsl")
    ? "local"
    : origin.includes("dev-connect")
    ? "dev"
    : origin.includes("dev-admin")
    ? "dev-admin"
    : origin.includes("qa-connect")
    ? "qa"
    : origin.includes("qa-admin")
    ? "qa-admin"
    : origin.includes("drawbridgeconnectqa.com")
    ? "stg"
    : origin.includes("admin.drawbridgeconnectqa.com")
    ? "stg-admin"
    : origin.includes("drawbridgeconnectstg.com")
    ? "stg"
    : origin.includes("admin.drawbridgeconnectstg.com")
    ? "stg-admin"
    : origin.includes("stg-connect")
    ? "stg"
    : origin.includes("stg-admin")
    ? "stg-admin"
    : origin.includes("drawbridgeconnect.com")
    ? "prod"
    : origin.includes("client.drawbridgeconnect.com")
    ? "prod"
    : origin.includes("prod-connect")
    ? "prod"
    : origin.includes("admin.drawbridgeconnect.com")
    ? "prod-admin"
    : origin.includes("dev.drawbridgeconnect.com")
    ? "prod-admin"
    : undefined;

  return environment;
};

export const isEnvironment = (environments) => {
  const environment = getEnvironment();
  return environments?.includes(environment?.toUpperCase());
};
